import React, { Component, useEffect, useState } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import ReactApexChart from "react-apexcharts";
import Tendance from "../Tendance/Tendance.jsx";
import ReactToPrint from "react-to-print";
import GraphiquePulseur from "../GraphiquePulseur/GraphiquePulseur.jsx";
import { BsCircleFill } from "react-icons/bs";

class ResultatPulseur extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Vert", "Orange", "Rouge"],
        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 80,
              },

              legend: {
                show: false, // Masquer la légende
              },
            },
          },
        ],
      },

      //Champs du formulaire
      idEquipe: " ",
      idCritere: [],
      result: [],
      resultN1: [],
      resultN2: [],
      commentaireRegroupes: {},
      afficheResult: false,

      //Erreur de champs
      loadingCritere: true,
      commentaire: true,
    };
  }

  calculateAndSortByColorPercentages(data) {
    // Créez un objet pour stocker les pourcentages de couleur par critère
    const criteriaColorPercentages = {};
    console.log("ici", data);

    // Parcourez le tableau pour calculer les pourcentages de couleur par critère
    data.forEach((item) => {
      const { ID_CRITERE, COULEUR, LIBELLE } = item;

      if (!criteriaColorPercentages[ID_CRITERE]) {
        criteriaColorPercentages[ID_CRITERE] = {
          Vert: 0,
          Orange: 0,
          Rouge: 0,
          Total: 0,
          Libelle: LIBELLE, // Utilisez la propriété "LIBELLE" pour le libellé
        };
      }
      criteriaColorPercentages[ID_CRITERE].Total++;
      if (COULEUR === "Vert") {
        criteriaColorPercentages[ID_CRITERE].Vert++;
      } else if (COULEUR === "Orange") {
        criteriaColorPercentages[ID_CRITERE].Orange++;
      } else if (COULEUR === "Rouge") {
        criteriaColorPercentages[ID_CRITERE].Rouge++;
      }
    });

    // Créez un tableau pour stocker les résultats triés
    const sortedResults = [];

    // Parcourez l'objet des pourcentages calculés et créez un tableau de résultats triés
    for (const ID_CRITERE in criteriaColorPercentages) {
      const percentages = criteriaColorPercentages[ID_CRITERE];

      const total = percentages.Total;
      const vertPercentage = (percentages.Vert / total) * 100;
      const orangePercentage = (percentages.Orange / total) * 100;
      const rougePercentage = (percentages.Rouge / total) * 100;

      sortedResults.push({
        id: ID_CRITERE,
        libelle: percentages.Libelle,
        pourcentageVert: vertPercentage.toFixed(1) + "%",
        pourcentageOrange: orangePercentage.toFixed(1) + "%",
        pourcentageRouge: rougePercentage.toFixed(1) + "%",
      });
    }

    // Triez le tableau de résultats en fonction du pourcentage de Rouge et Orange (le plus élevé d'abord)
    sortedResults.sort((a, b) => {
      if (a.pourcentageRouge !== b.pourcentageRouge) {
        return parseFloat(b.pourcentageRouge) - parseFloat(a.pourcentageRouge);
      }
      if (a.pourcentageOrange !== b.pourcentageOrange) {
        return (
          parseFloat(b.pourcentageOrange) - parseFloat(a.pourcentageOrange)
        );
      }
      return parseFloat(b.pourcentageVert) - parseFloat(a.pourcentageVert);
    });

    this.setState({
      result: sortedResults,
    });
    console.log("test", this.state.result);

    return sortedResults;
  }

  groupCommentsByCriteria(apiResponse) {
    const commentairesParCritere = {};

    apiResponse.forEach((item) => {
      const critereId = item.ID_CRITERE;
      const commentaire = item.COMMENTAIRE;

      if (!commentairesParCritere[critereId]) {
        commentairesParCritere[critereId] = [];
      }

      commentairesParCritere[critereId].push(commentaire);
    });

    return commentairesParCritere;
  }

  // fonction qui mlange les tableaux
  shuffleArray(array) {
    // Créez une copie du tableau pour ne pas le modifier directement
    const shuffledArray = array.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Générez un index aléatoire
      const j = Math.floor(Math.random() * (i + 1));

      // Échangez les éléments aux indices i et j
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  }

  // fonction qui calule les resultat pour le graphique
  calculateGreenPercentage = (results, critereId) => {
    const totalResponses = results.filter(
      (result) => result.ID_CRITERE === critereId
    ).length;
    const greenResponses = results.filter(
      (result) => result.ID_CRITERE === critereId && result.COULEUR === "Vert"
    ).length;
    return (greenResponses / totalResponses) * 100;
  };

  // fonction qui calule la tendance
  // calculatePercentageExcludingImprovementForCriteria = (results, critereId) => {
  //   // Filtrer les réponses par critère spécifié (critereId)
  //   const filteredResponses = results.filter(
  //     (response) => response.ID_CRITERE === critereId
  //   );

  //   // Compter le nombre total de réponses "amelioration" pour le critère spécifié
  //   const improvementCount = filteredResponses.filter(
  //     (response) => response.TENDANCE === "amelioration"
  //   ).length;

  //   const stableCount = filteredResponses.filter(
  //     (response) => response.TENDANCE === "stable"
  //   ).length;

  //   const degradationCount = filteredResponses.filter(
  //     (response) => response.TENDANCE === "degradation"
  //   ).length;

  //   const PointStable = stableCount * 0.5;
  //   const PointDegradation = degradationCount * 0;

  //   // Filtrer toutes les réponses différentes de "amelioration" pour le critère spécifié
  //   // const totalOtherResponses = filteredResponses.filter(
  //   //   (response) => response.TENDANCE !== "amelioration"
  //   // ).length;

  //   // Si le nombre de réponses "amelioration" est de 0, retourner 0 pour éviter une division par 0

  //   // Calculer le pourcentage des réponses différentes de "amelioration" par rapport au nombre total de réponses "amelioration" pour le critère spécifié
  //   if (improvementCount === 0) {
  //     if (stableCount === 0) {
  //       const percentage = 0;
  //       return percentage.toFixed(1);
  //     }
  //     const percentage =
  //       ((PointStable - PointDegradation) / (stableCount + degradationCount)) *
  //       100;
  //     return percentage.toFixed(1);
  //   }

  //   const percentage =
  //     ((improvementCount - (PointStable + PointDegradation)) /
  //       (improvementCount + stableCount + degradationCount)) *
  //     100;

  //   if (percentage > 100 && percentage < 0) {
  //     const percentage = "Probleme de calcul";
  //     return percentage;
  //   }

  //   // Retourner le pourcentage
  //   return percentage.toFixed(1);
  // };

  // fonction qui calule la tendance
  calculatePercentageExcludingImprovementForCriteria = (results, critereId) => {
    // Filtrer les réponses par critère spécifié (critereId)
    const filteredResponses = results.filter(
      (response) => response.ID_CRITERE === critereId
    );

    // Compter le nombre total de réponses pour chaque type
    const improvementCount = filteredResponses.filter(
      (response) => response.TENDANCE === "amelioration"
    ).length;

    const stableCount = filteredResponses.filter(
      (response) => response.TENDANCE === "stable"
    ).length;

    const degradationCount = filteredResponses.filter(
      (response) => response.TENDANCE === "degradation"
    ).length;

    // Calculer le nombre total de réponses
    const totalResponses = filteredResponses.length;

    // Calculer le poids total en fonction des réponses
    const totalWeight =
      improvementCount * 1 + stableCount * 0.5 + degradationCount * 0;

    // Calculer le pourcentage général en fonction du poids total
    const percentageGeneral = (totalWeight / totalResponses) * 100;

    return percentageGeneral.toFixed(1);
  };

  //Fonction au chargement de la page pour récupérer les informations d'affichage
  async componentDidMount() {
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    await fetch("/api/isAdmin", requestOptions).then((res) => {
      if (res.status === 200 || res.status === 304) {
        this.setState({ isAdmin: true, loading: false });
      } else {
        this.setState({ isAdmin: false, loading: false });
      }
    });
    //Récupération de id Equipe

    const bodyIdPulseur = {
      idPulseur: this.props.params.id,
    };

    const get = {
      method: "POST",
      body: JSON.stringify(bodyIdPulseur),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/criteres/getCritereByPulseur", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          this.state.idCritere.push(data[i].ID_CRITERE);
        }
        this.setState({
          criteres: data,
        });
        console.log("criteres", this.state.criteres);
      })
      .catch((error) => {
        console.log(error);
      });

    await fetch("/api/equipes/getEquipeByPulseur", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          idEquipe: data.ID_EQUIPE,
          libelle_equipe: data.LIBELLE_EQUIPE,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const body = {
      idPulseur: this.props.params.id,
      idEquipe: this.state.idEquipe,
    };

    const getResultatByPulseur = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/pulseur/getResultatByPulseur", getResultatByPulseur)
      .then((res) => {
        if (res.status === 401) {
          this.setState({ Acces: true });
        }

        return res.json();
      })
      .then((data) => {
        this.setState({
          resultat: data.resultat,
          resultatN1: data.resultatN1,
          resultatN2: data.resultatN2,
        });

        const commentairesRegroupes = this.groupCommentsByCriteria(
          data.resultat
        );
        this.setState({
          commentaireRegroupes: commentairesRegroupes,
        });

        this.setState({
          loadingCritere: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.calculateAndSortByColorPercentages(this.state.resultat);
  }

  //Fonction de rendu

  render() {
    if (this.state.Acces === true) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          Unauthorized
        </h1>
      );
    } else if (this.state.loadingCritere) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          loading
        </h1>
      );
    } else if (this.state.isAdmin) {
      return (
        <>
          <div ref={this.componentRef}>
            <h1 className="text-center text-orange-incubate text-lg mt-5">
              Resultat Pulseur :{" "}
              <span className="ml-3 text-orange-incubate underline">
                {this.state.libelle_equipe}
              </span>
            </h1>
            <div className="flex justify-center">
              <button
                className="bg-bleu-incubate text-white rounded-lg shadow-lg p-2 mt-5 mb-5 ml-2"
                onClick={() => {
                  if (this.state.afficheResult === true) {
                    this.setState({ afficheResult: false });
                  } else {
                    this.setState({ afficheResult: true });
                  }
                }}>
                Tableau récapilulatif
              </button>
            </div>

            {/* table */}

            {this.state.afficheResult === true ? (
              <>
                {" "}
                <div className="flex justify-center mt-5 ">
                  <table className="shadow-lg bg-white rounded-lg">
                    <thead>
                      <tr className="text-center ">
                        <th className="text-sm  text-bleu-incubate bg-blue-50">
                          Critères
                        </th>

                        <th className="text-sm text-bleu-incubate bg-green-100">
                          % Vert
                        </th>
                        <th className="text-sm text-bleu-incubate bg-orange-100">
                          % Orange
                        </th>
                        <th className="text-sm text-orange-incubate bg-red-100">
                          % Rouge
                        </th>
                      </tr>
                    </thead>
                    {this.state.result.map((element, index) => (
                      <tbody>
                        <td className=" text-orange-incubate p-2 text-sm ">
                          {element.libelle}
                        </td>
                        <td className=" bg-green-50 p-2 text-sm">
                          {element.pourcentageVert}
                        </td>
                        <td className=" bg-orange-50 p-2 text-sm">
                          {element.pourcentageOrange}
                        </td>
                        <td className=" bg-red-50 p-2 text-sm">
                          {element.pourcentageRouge}
                        </td>
                      </tbody>
                    ))}
                  </table>
                </div>
              </>
            ) : null}

            <div className="flex justify-center  items-center mt-5 p-4  rounded-lg  w-3/4 mx-auto">
              <table>
                <thead></thead>
                <tbody>
                  {this.state.idCritere.map((element, index) => (
                    <React.Fragment key={index}>
                      <tr className="text-center ">
                        <th className="text-xl  text-bleu-incubate">Critère</th>

                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-2
                        </th>
                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-1
                        </th>
                        <th className="text-xl text-orange-incubate">
                          Pulseur
                        </th>

                        <th className="text-xl text-orange-incubate">
                          Tendance
                        </th>
                        <th className="text-xl text-orange-incubate">
                          {" "}
                          Evolution
                        </th>
                      </tr>
                      <tr className=" rounded-xl   shadow-lg mt-2" key={index}>
                        {/* criteres  */}
                        <td className=" text-orange-incubate p-5 text-xl">
                          {index + 1}
                          {")"} {this.state.criteres[index].LIBELLE}
                          <div className="flex items-center space-x-2 mt-2">
                            <div className="">
                              <BsCircleFill color="green" />
                            </div>
                            <p className="text-sm text-green-800 flex">
                              {" "}
                              {this.state.criteres[index].LIBELLE_VERT}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2 mt-2">
                            <div>
                              <BsCircleFill color="red" />{" "}
                            </div>

                            <p className="text-sm text-red-800">
                              {this.state.criteres[index].LIBELLE_ROUGE}
                            </p>
                          </div>
                        </td>

                        {/* resultat N-2 */}

                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN2,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N-1 */}
                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN1,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N */}
                        <td>
                          <div className="flex items-center p-4 justify-center shadow-2xl bg-gray-50 rounded-lg border border-orange-incubate">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultat,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* tendance */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <Tendance
                              resultat={this.calculatePercentageExcludingImprovementForCriteria(
                                this.state.resultat,
                                element
                              )}
                            />
                          </div>
                        </td>
                        {/* graphique */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <GraphiquePulseur
                              greenPercentageN={this.calculateGreenPercentage(
                                this.state.resultat,
                                element
                              ).toFixed(1)}
                              greenPercentageN1={this.calculateGreenPercentage(
                                this.state.resultatN1,
                                element
                              ).toFixed(1)}
                              greenPercentageN2={this.calculateGreenPercentage(
                                this.state.resultatN2,
                                element
                              ).toFixed(1)}
                            />
                          </div>
                        </td>
                      </tr>
                      {/* Commentaires */}
                      <tr>
                        <td colSpan="6">
                          {this.state.commentaireRegroupes[element].length &&
                          Object.keys(this.state.commentaireRegroupes[element])
                            .length > 0 ? (
                            <>
                              <h2 className="text-center underline mt-2 ">
                                Commentaires associés à ce critère
                              </h2>
                              <div className="grid grid-cols-5 gap-2 justify-center mt-1 mb-2">
                                {this.shuffleArray(
                                  Object.values(
                                    this.state.commentaireRegroupes[element]
                                  )
                                ).map((commentaire, commentaireIndex) => (
                                  <div className="shadow-md bg-sky-50 rounded-sm  p-2 w-52 h-auto">
                                    <p
                                      className="whitespace-normal"
                                      key={commentaireIndex}>
                                      {commentaire}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <p>Pas de commentaires pour ce critère</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6">
                          <hr className="bg-gray-900 mx-auto w-96 mb-5" />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {}
          <div className="flex justify-center rounded-lg shadow-lg border mx-auto mt-3 mb-3 text-orange-incubate border-orange-incubate hover:bg-orange-incubate hover:text-white w-32 p-2">
            <ReactToPrint
              trigger={() => <button>Exporter</button>}
              content={() => this.componentRef.current}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <h1 className="text-center text-orange-incubate text-lg mt-5">
              Resultat Pulseur :{" "}
              <span className="ml-3 text-orange-incubate underline">
                {this.state.libelle_equipe}
              </span>
            </h1>
            <div className="flex justify-center">
              <button
                className="bg-bleu-incubate text-white rounded-lg shadow-lg p-2 mt-5 mb-5 ml-2"
                onClick={() => {
                  if (this.state.afficheResult === true) {
                    this.setState({ afficheResult: false });
                  } else {
                    this.setState({ afficheResult: true });
                  }
                }}>
                Tableau récapilulatif
              </button>
            </div>

            {/* table */}

            {this.state.afficheResult === true ? (
              <>
                {" "}
                <div className="flex justify-center mt-5">
                  <table>
                    <thead>
                      <tr className="text-center ">
                        <th className="text-sm  text-bleu-incubate bg-blue-50">
                          Critères
                        </th>

                        <th className="text-sm text-bleu-incubate bg-green-100">
                          % Vert
                        </th>
                        <th className="text-sm text-bleu-incubate bg-orange-100">
                          % Orange
                        </th>
                        <th className="text-sm text-orange-incubate bg-red-100">
                          % Rouge
                        </th>
                      </tr>
                    </thead>
                    {this.state.result.map((element, index) => (
                      <tbody>
                        <td className=" text-orange-incubate p-2 text-sm bg-blue-50">
                          {element.libelle}
                        </td>
                        <td className=" bg-green-50 p-2 text-sm">
                          {element.pourcentageVert}
                        </td>
                        <td className=" bg-orange-50 p-2 text-sm">
                          {element.pourcentageOrange}
                        </td>
                        <td className=" bg-red-50 p-2 text-sm">
                          {element.pourcentageRouge}
                        </td>
                      </tbody>
                    ))}
                  </table>
                </div>
              </>
            ) : null}

            <div className="flex justify-center  items-center mt-5 p-4  rounded-lg  w-3/4 mx-auto">
              <table>
                <thead></thead>
                <tbody>
                  {this.state.idCritere.map((element, index) => (
                    <React.Fragment key={index}>
                      <tr className="text-center ">
                        <th className="text-xl  text-bleu-incubate">Critère</th>

                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-2
                        </th>
                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-1
                        </th>
                        <th className="text-xl text-orange-incubate">
                          Pulseur
                        </th>

                        <th className="text-xl text-orange-incubate">
                          Tendance
                        </th>
                        <th className="text-xl text-orange-incubate">
                          {" "}
                          Evolution
                        </th>
                      </tr>
                      <tr className=" rounded-xl   shadow-lg mt-2" key={index}>
                        {/* criteres  */}
                        <td className="text-md text-orange-incubate p-5 ">
                          {index + 1}
                          {")"} {this.state.criteres[index].LIBELLE}
                          <div className="flex items-center space-x-2 mt-2">
                            <div className="">
                              <BsCircleFill color="green" />
                            </div>
                            <p className="text-sm text-green-800 flex">
                              {" "}
                              {this.state.criteres[index].LIBELLE_VERT}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2 mt-2">
                            <div>
                              <BsCircleFill color="red" />{" "}
                            </div>

                            <p className="text-sm text-red-800">
                              {this.state.criteres[index].LIBELLE_ROUGE}
                            </p>
                          </div>
                        </td>

                        {/* resultat N-2 */}

                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN2,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N-1 */}
                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN1,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N */}
                        <td>
                          <div className="flex items-center p-4 justify-center shadow-2xl bg-gray-50 rounded-lg border border-orange-incubate">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultat,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* tendance */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <Tendance
                              resultat={this.calculatePercentageExcludingImprovementForCriteria(
                                this.state.resultat,
                                element
                              )}
                            />
                          </div>
                        </td>
                        {/* graphique */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <GraphiquePulseur
                              greenPercentageN={this.calculateGreenPercentage(
                                this.state.resultat,
                                element
                              ).toFixed(1)}
                              greenPercentageN1={this.calculateGreenPercentage(
                                this.state.resultatN1,
                                element
                              ).toFixed(1)}
                              greenPercentageN2={this.calculateGreenPercentage(
                                this.state.resultatN2,
                                element
                              ).toFixed(1)}
                            />
                          </div>
                        </td>
                      </tr>
                      {/* Commentaire */}
                      <tr>
                        <td colSpan="6">
                          {this.state.commentaireRegroupes[element].length &&
                          Object.keys(this.state.commentaireRegroupes[element])
                            .length > 0 ? (
                            <>
                              <h2 className="text-center underline mt-2 ">
                                Commentaires associés à ce critère
                              </h2>
                              <div className="grid grid-cols-5 gap-2 justify-center mt-1 mb-2">
                                {this.shuffleArray(
                                  Object.values(
                                    this.state.commentaireRegroupes[element]
                                  )
                                ).map((commentaire, commentaireIndex) => (
                                  <div className="shadow-md bg-sky-50 rounded-sm  p-2 w-52 h-auto">
                                    <p
                                      className="whitespace-normal"
                                      key={commentaireIndex}>
                                      {commentaire}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <p>Pas de commentaires pour ce critère</p>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    }
  }
}

function Pie(element) {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  let vert = 0;
  let orange = 0;
  let rouge = 0;

  for (let i = 0; i < element.element.resultat.length; i++) {
    if (element.element.resultat[i].ID_CRITERE === element.element.element) {
      if (element.element.resultat[i].COULEUR === "Vert") {
        vert = vert + 1;
      } else if (element.element.resultat[i].COULEUR === "Orange") {
        orange = orange + 1;
      } else if (element.element.resultat[i].COULEUR === "Rouge") {
        rouge = rouge + 1;
      }
    }
  }

  useEffect(() => {
    setSeries([vert, orange, rouge]);
    setLoading(false);
  }, []);

  const [options] = useState({
    colors: ["#01D758", "#ED7F10", "#ED0000"],
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Vert", "Orange", "Rouge"],
    legend: {
      show: false, // Masquer la légende
    },
    dataLabels: {
      style: {
        colors: ["#000"], // Couleur du texte en noir
      },
    },
  });

  if (loading) {
    return <h1> Loading...</h1>;
  } else {
    return (
      <div className="flex  w-full basis-1/4 justify-center">
        {series[0] === 0 && series[1] === 0 && series[2] === 0 ? (
          <div className="">
            {" "}
            <p className="text-md flex items-center text-center">
              Aucun contenu disponible.
            </p>{" "}
          </div>
        ) : (
          <>
            <ReactApexChart
              className=" flex justify-center items-center "
              options={options}
              series={series}
              type="pie"
              width={180}
            />
          </>
        )}
      </div>
    );
  }
}



//Export
export default withRouter(ResultatPulseur);
