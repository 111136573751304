import {Routes, Route} from "react-router-dom";

import Menu from "./components/Outils/Menu.jsx";
import MenuNonConnecté from "./components/Outils/MenuNonConnecté.jsx";
import Home from './components/Home/Home.jsx';
import Login from "./components/Login/Login.jsx";
import ProtectedRoute from './components/Outils/ProtectedRoute';
import AdminRoute from "./components/Outils/AdminRoute.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import CreationSocietes from "./components/CreationSocietes/CreationSocietes.jsx";
import Societes from "./components/Societes/Societes.jsx";
import EditSocietes from "./components/EditSocietes/EditSocietes.jsx";
import Register from "./components/Register/Register.jsx";
import CreationEquipes from "./components/CreationEquipes/CreationEquipes.jsx";
import Criteres from "./components/Criteres/Criteres.jsx";
import CreationCriteres from "./components/CreationCriteres/CreationCriteres.jsx";
import EditCriteres from "./components/EditCriteres/EditCriteres.jsx";
import Users from "./components/Users/Users.jsx";
import EditUser from "./components/EditUser/EditUser.jsx";
import CreationPulseur from "./components/CreationPulseur/CreationPulseur.jsx";
import EquipePulseur from "./components/EquipePulseur/EquipePulseur.jsx";
import ReponsePulseur from "./components/ReponsePulseur/ReponsePulseur.jsx";
import MesPulseur from "./components/MesPulseur/MesPulseur.jsx";
import ResultatPulseurIndividu from "./components/ResultatPulseurIndividu/ResultatPulseurIndividu.jsx";

import ResultatPulseur from "./components/ResultatPulseur/ResultatPulseur.jsx";
import EditMe from "./components/EditUser/EditMe";



export default function App() {
  return (
    <div className="App ">
      <Routes>
        <Route path='/' element={<><MenuNonConnecté/><Home /></>} />
        
        <Route path="/login" element={<> <Login/></>} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<><Menu /> <Dashboard /></>}/>
          <Route path="/editUser" element={<> <EditMe /></>}/>
          <Route path="/reponsePulseur/:id" element={<><Menu /> <ReponsePulseur/></>}/>
          <Route path="/resultatPulseur/:id" element={<><Menu /> <ResultatPulseur/></>}/>
          <Route path="/resultatPulseurIndividu/:id_pulseur/:id_pulseur_individu" element={<><Menu /> <ResultatPulseurIndividu/></>}/>
          <Route path="/mesPulseurs" element={<><Menu /> <MesPulseur/></>}/>
          <Route path="/" element={<AdminRoute />}>
            <Route path="/creationSociete" element={<><Menu /> <CreationSocietes /></>}/>
            <Route path="/societes" element={<><Menu /> <Societes /></>}/>
            <Route path="/societeEdit/:id" element={<><Menu /> <EditSocietes /></>}/>
            <Route path='/register' element={<><Menu /> <Register /></>}/>
            <Route path="/creationEquipes/:id" element={<><Menu /> <CreationEquipes /></>}/>
            <Route path="/creationPulseur/:id" element={<><Menu /> <CreationPulseur /></>}/>
            <Route path="/criteres/:id" element={<><Menu /> <Criteres /></>}/>
            <Route path="/creationCriteres/:id" element={<><Menu /> <CreationCriteres /></>}/>
            <Route path="/editCriteres/:id" element={<><Menu /> <EditCriteres /></>}/>
            <Route path="/users" element={<><Menu /> <Users /></>}/>
            <Route path="/userEdit/:id" element={<><Menu /> <EditUser /></>}/>
            <Route path="/equipePulseur/:id" element={<><Menu /> <EquipePulseur/></>}/>
           
          </Route>
        </Route>
      </Routes>
        
    </div>
  );
}