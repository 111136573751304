import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { BiRightArrowAlt } from "react-icons/bi";
import {
  BsCircleFill,
  BsFillEmojiSmileFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
} from "react-icons/bs";
import { FaArrowUp, FaArrowRight, FaArrowDown } from "react-icons/fa";

class ReponsePulseur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Champs du formulaire
      idSociete: " ",
      reponse: "",
      tendance: "",
      commentaire: "",

      //Erreur de champs
      loadingCritere: true,
    };
  }

  //detecte un changement
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleOptionChange = (changeEvent) => {
    this.setState({ reponse: changeEvent.target.value });
  };
  handleOptionChangeTendance = (changeEvent) => {
    this.setState({ tendance: changeEvent.target.value });
  };
  handleOptionChangeCommentaire = (changeEvent) => {
    this.setState({ commentaire: changeEvent.target.value });
  };

  validate() {
    let Error = "";

    //Validation libelle
    // if (
    //   !this.state.reponse &&
    //   !this.state.tendance &&
    //   this.state.commentaire === ""
    // ) {
    //   Error = "Sélectionner une réponse et n'oubliez pas la tendance";
    // }
    if (
      !this.state.reponse &&
      this.state.tendance === "" &&
      this.state.commentaire === ""
    ) {
      Error = "Veuillez remplir le formulaire, tous les champs sont requis";
    } else if (!this.state.reponse) {
      Error = "Veuillez sélectionner une réponse, svp.";
    } else if (!this.state.tendance) {
      Error = "N'oubliez pas de choisir une tendance, svp. ";
    } else if (!this.state.commentaire) {
      Error = "Veuillez laisser un commentaire, svp.";
    } else {
      Error = false;
    }

    //Retour d'erreur

    if (Error) {
      this.setState({ libelleError: Error });
      alert(Error);
      return false;
    } else {
      return true;
    }
  }

  //Fonction appalant l'api pour réaliser la création
  Suivant = (event) => {
    event.preventDefault();
    if (this.validate()) {
      const body = {
        idPulseurIndividu: this.state.idPulseurIndividu,
        idCritere: this.state.idCritere,
        couleur: this.state.reponse,
        tendance: this.state.tendance,
        commentaire: this.state.commentaire,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/pulseur/resultatPulseur", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            this.setState({ reponse: null, tendance: null, commentaire: "" });
            if (this.state.totalCritere === this.state.totalResultat + 1) {
              this.Terminer();
            } else {
              this.NextCritere();
            }
          } else {
            alert("Erreur ...");
            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  Terminer = () => {
    const body = {
      idPulseur: this.props.params.id,
      statut: "Terminé",
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/pulseur/modifStatut", requestOptions)
      .then((res) => {
        if (res.status === 200) {
          alert("Pulseur Terminé !");
          this.props.navigate(`/resultatPulseur/${this.props.params.id}`);
        } else {
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  NextCritere = () => {
    const body = {
      idSociete: this.state.idSociete,
      idPulseur: this.props.params.id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/pulseur/getNextQuestion", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          idCritere: data.ID_CRITERE,
          libelle: data.LIBELLE,
          libelleRouge: data.LIBELLE_ROUGE,
          libelleVert: data.LIBELLE_VERT,
          description: data.DESCRIPTION,
          image: data.LOGO,
          totalResultat: data.totalResultat,
        });
        this.setState({ loadingCritere: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fonction au chargement de la page pour récupérer les informations d'affichage
  async componentDidMount() {
    //Récupération de id Societe
    const bodyIdSociete = {
      idPulseur: this.props.params.id,
    };

    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify(bodyIdSociete),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/pulseur/getStatut", getByIdOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          statut: data.STATUT,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    await fetch("/api/societes/getSocieteByPulseur", getByIdOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          idSociete: data.ID_SOCIETE,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //Récupération des criteres
    const body = {
      idSociete: this.state.idSociete,
      idPulseur: this.props.params.id,
    };

    const getByIdOptions2 = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/pulseur/getNextQuestion", getByIdOptions2)
      .then((res) => {
        if (res.status === 401) {
          this.setState({ Acces: true });
        }
        return res.json();
      })
      .then((data) => {
        this.setState({
          idCritere: data.ID_CRITERE,
          libelle: data.LIBELLE,
          libelleRouge: data.LIBELLE_ROUGE,
          libelleVert: data.LIBELLE_VERT,
          description: data.DESCRIPTION,
          image: data.LOGO,
          totalCritere: data.totalCritere,
          totalResultat: data.totalResultat,
          idPulseurIndividu: data.idPulseurIndividu,
        });

        this.setState({ loadingCritere: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Fonction de rendu
  render() {
    if (this.state.Acces === true) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          Unauthorized
        </h1>
      );
    } else if (this.state.statut === "Terminé") {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          Pulseur Terminé
        </h1>
      );
    } else if (this.state.loadingCritere) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          loading
        </h1>
      );
    } else {
      return (
        <>
          <h1 className="flex justify-center  text-xl text-orange-incubate sm:mt-2 xl:flex xl:flex-row xl:justify-center xl:text-2xl xl:text-orange-incubate md:flex md:justify-center md:text-2xl md:text-orange-incubate lg:justify-center lg:text-2xl lg:text-orange-incubate ">
            Pulseur {new Date().getFullYear()}
          </h1>
          <div className=" flex flex-col justify-center items-center mt-10 p-4 sm:flex sm:flex-col  sm:justify-center sm:mt-10 xl:flex xl:flex-row xl:justify-center xl:items-start xl:mt-16 xl:p-4 lg:flex lg:flex-row lg:justify-center lg:items-start md:flex md:flex-row md:justify-center md:items-start md:p-6 md:mt-0">
            {/* Card*/}
            <div className="flex w-5/6 sm:flex sm:flex-col sm:w-2/3 md:flex-col md:w-2/3  xl:flex xl:flex-row xl:w-1/3 ">
              <div className="shadow-lg rounded-3xl w-full mx-auto mb-5 flex flex-col bg-cyan-50 sm:w-full sm:mx-auto xl:w-full xl:mx-20 xl:mb-5 ">
                <h1 className="items-start justify-center flex text-lg p-2 font-bold uppercase">
                  {this.state.libelle}
                </h1>
                <div className="justify-center items-center flex  ">
                  <img
                    src={this.state.image}
                    alt="critère"
                    className="w-64  h-52 rounded-lg mt-2"
                  />
                </div>
                <div className="basis-1/3 flex flex-col">
                  <p className="justify-center flex p-4 text-center font-bold text-black">
                    {this.state.description}
                  </p>
                </div>
                <div className="m-2 text-center flex flex-row basis-1/6">
                  <p className="block font-semibold text-green-500 text-lg pb-2 pl-2">
                    <BsCircleFill size="50" />
                  </p>
                  <p className="px-5 text-green-700">
                    {this.state.libelleVert}
                  </p>
                </div>
                <div className="m-2 text-center flex flex-row basis-1/6">
                  <p className="block font-semibold text-red-500 text-lg pb-2 pl-2">
                    <BsCircleFill size="50" />
                  </p>
                  <p className="px-5 text-red-700">{this.state.libelleRouge}</p>
                </div>
                <p className="flex justify-center font-bold mb-1">
                  critère n° {this.state.totalResultat + 1}/
                  {this.state.totalCritere}
                </p>
              </div>
            </div>
            <div className=" flex flex-col w-5/6 mt-5 sm:flex sm:flex-col sm:w-2/3 sm:mt-5 xl:flex xl:flex-col xl:w-2/3 xl:ml-4">
              <div className="justify-center items-center flex flex-col h-full sm:flex sm:flex-col xl:flex xl:flex-row ">
                <div className="justify-center items-center flex-col  h-full">
                  <h2 className="text-black text-xl italic text-center sm:mb-2 sm:text-xl">
                    Choisissez une couleur{" "}
                  </h2>
                  <div className="justify-center items-center flex  h-full">
                    {/* Cercle*/}
                    <label>
                      <input
                        checked={this.state.reponse === "Vert"}
                        onChange={this.handleOptionChange}
                        className="hidden"
                        type="radio"
                        id="vert"
                        name="vert"
                        value="Vert"
                      />
                      <div className="label-checked:bg-green-200 transition m-2 p-4 lg:m-5 lg:p-5 rounded-full flex shadow-lg hover:-translate-1 hover:scale-110 duration-300 ">
                        <BsFillEmojiSmileFill
                          size="70"
                          className="text-green-600"
                        />
                      </div>
                    </label>
                    <label>
                      <input
                        checked={this.state.reponse === "Orange"}
                        onChange={this.handleOptionChange}
                        className="hidden"
                        type="radio"
                        id="orange"
                        name="orange"
                        value="Orange"
                      />
                      <div className="label-checked:bg-orange-200 transition  m-2 p-4 lg:m-5 lg:p-5  rounded-full flex shadow-lg hover:-translate-1 hover:scale-110 duration-300 ">
                        <BsFillEmojiNeutralFill
                          size="70"
                          className="text-orange-400 "
                        />
                      </div>
                    </label>
                    <label>
                      <input
                        checked={this.state.reponse === "Rouge"}
                        onChange={this.handleOptionChange}
                        className="hidden"
                        type="radio"
                        id="rouge"
                        name="rouge"
                        value="Rouge"
                      />
                      <div className="label-checked:bg-red-200 transition   m-2 p-4 lg:m-5 lg:p-5 flex shadow-lg rounded-full hover:-translate-1 hover:scale-110 duration-300 ">
                        <BsFillEmojiFrownFill
                          size="70"
                          className="text-red-400"
                        />
                      </div>
                    </label>
                  </div>
                </div>
                {/* Tendance */}
                <div className="justify-center items-center flex flex-col h-full w-1/2 sm:w-full ">
                  <h2 className="text-black text-xl italic sm:mb-2 sm:mt-2">
                    Choisissez une tendance
                  </h2>

                  <label>
                    <input
                      checked={this.state.tendance === "amelioration"}
                      onChange={this.handleOptionChangeTendance}
                      className="hidden"
                      type="radio"
                      id="amelioration"
                      name="amelioration"
                      value="amelioration"
                    />
                    <div className="  justify-center  label-checked:bg-green-200 transition m-2 p-4 lg:m-5 lg:p-5 rounded-full flex shadow-lg hover:-translate-1 hover:scale-90 duration-300 items-center ">
                      <FaArrowUp size="40" className="text-green-600" />
                      <p className="ml-5">
                        Je sens que cet indicateur va s’améliorer
                      </p>
                    </div>
                  </label>
                  <label>
                    <input
                      checked={this.state.tendance === "stable"}
                      onChange={this.handleOptionChangeTendance}
                      className="hidden"
                      type="radio"
                      id="stable"
                      name="stable"
                      value="stable"
                    />
                    <div className="justify-center  label-checked:bg-orange-200 transition  m-2 p-4 lg:m-5 lg:p-5  rounded-full flex shadow-lg hover:-translate-1 hover:scale-90 duration-300 items-center ">
                      <FaArrowRight size="40" className="text-orange-400 " />
                      <p className="ml-5">
                        Je sens que cet indicateur va rester stable
                      </p>
                    </div>
                  </label>
                  <label>
                    <input
                      checked={this.state.tendance === "degredation"}
                      onChange={this.handleOptionChangeTendance}
                      className="hidden"
                      type="radio"
                      id="degredation"
                      name="degredation"
                      value="degredation"
                    />
                    <div className="justify-center  label-checked:bg-red-200 transition   m-2 p-4 lg:m-5 lg:p-5 flex shadow-lg rounded-full hover:-translate-1 hover:scale-90 duration-300 items-center ">
                      <FaArrowDown size="40" className="text-red-400" />
                      <p className="ml-5">
                        Je sens que cet indicateur va se dégrader
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              {/* Commentaire */}

              <div className=" items-center flex flex-col justify-center p-4 mt-5">
                
                <h2 className="flex justify-center  text-xl text-black italic mb-1">
                  Merci de laisser un commentaire
                </h2>
                <div className=" mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 w-full sm:w-full xl:w-1/2">
                  <div className="px-1 py-2 bg-white rounded-t-lg dark:bg-gray-800 ">
                    <textarea
                      onChange={this.handleOptionChangeCommentaire}
                      value={this.state.commentaire}
                      rows="4"
                      className="w-full  px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                      placeholder="...."
                      required></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" justify-end mr-32 flex  items-center sm:justify-center sm:mr-0 sm:mb-2 md:justify-end md:mr-32 md:mb-2">
            <button
              onClick={this.Suivant}
              className="text-md  p-4 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate border-orange-incubate border">
              <div className="flex flex-row items-center">
                <p className="px-2 text-white">Suivant </p>
              </div>
            </button>
          </div>
        </>
      );
    }
  }
}

//Export
export default withRouter(ReponsePulseur);
