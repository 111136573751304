import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import SortableTree, { getTreeFromFlatData, getFlatDataFromTree, getVisibleNodeCount } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import Select from 'react-select';


class CreationEquipes extends Component {
    //constructeur du composant
    constructor(props){
        super(props);
        this.state = {
            //Informations de la société
            libelleSociete : '',
            equipes : [],

            //Création d'une équipe
            nom : '',
            nomError : '',

            //Suppression
            listeEquipeDel : [],
            idEquipeDel : null,

            //Tree
            listeEquipeTree : [],

            //Fonctionnement de la page
            loadingSociete : true,
            loadingEquipe : true,
            showModalAdd : false,
            showModalDel : false,
            showModalValidDel : false,
            showModalAnnuler : false,
            modification : false,
            count : 0,
        }
    }

    //Fonction permetant de valider les champs d'un formulaire
    validate = () => {
        let nomError = '';
        //Vérification du nom conforme pour l'équipe
        if(!this.state.nom){
            nomError = "Veuillez renseigner un nom pour l'équipe";
        }

        if(nomError){
            this.setState({nomError});
            return false;
        }else{
            this.setState({nomError});
            return true;
        }
    }

    //Fonction permetant d'ajouter une équipe en base de données
    ajouter = (event) => {
        event.preventDefault();
        if(this.validate()){
            const body = {
                idSociete : this.props.params.id,
                libelleEquipe : this.state.nom
            };
    
            const Options = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
            }; 
    
            fetch('/api/equipes/', Options)
                .then(res => {
                    if(res.status===449){
                        alert("Le nom de l'équipe est déjà existant pour cette société ...");
                    }  else if(res.status===500){
                        alert("Erreur interne, veuillez réessayer ...");
                    }else{
                        alert("Equipe créée avec succès !");
                        this.setState({nom : '', showModalAdd:false});
                        this.fetchEquipes();
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    //fonction permetant de supprimer une équipe
    supprimer = (event) => {
        event.preventDefault();
        
        const body = {
            idSociete : this.props.params.id,
            idEquipe : this.state.idEquipeDel,
        };

       

        const Options = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        //requete récupératn les informations de la société
        fetch('/api/equipes/delete', Options)
            .then(res => {
                if(res.status===500){
                    alert("Erreur interne, veuillez réessayer ...");
                    this.setState({showModalValidDel:false});
                }else{
                    alert("Équipe supprimée avec succès !");
                    this.setState({idEquipeDel:null, showModalValidDel:false, showModalDel:false});
                    this.fetchEquipes();
                }
            }).catch(error => {
                console.log(error);
            });
    }

    //Permet de modifier les champs lrosque l'on écrrit dedans
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
    }

    //Permet de récupérer les supression
    handleChangeDel = (event) => {
        this.setState({
          idEquipeDel : event.value,
        });
    }

    //Requete récupératn les infos de la societé
    fetchSociete = () => {
        //Récupération des information de la société
        const body = {
            idSociete : this.props.params.id,
        };

        const Options = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        //requete récupératn les informations de la société
        fetch('/api/societes/getSocieteById', Options)
            .then(res => {
                return res.json();  
            }).then(data => {
                this.setState ({
                    libelleSociete : data.LIBELLE_SOCIETE,
                });
                this.setState({loadingSociete : false})
            }).catch(error => {
                console.log(error);
            });
    }

    //Requete récupérant les équipe de la société
    fetchEquipes = () => {
        const body = {
            idSociete : this.props.params.id,
        };

        const Options = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        //Requete récupérant les équipes de la société
        fetch('/api/equipes/getAllEquipes', Options)
        .then(resEquipes => {
            return resEquipes.json();  
        }).then(dataEquipes => {
            
            //Récupération des équipes
            this.setState ({
                equipes : dataEquipes,
            });

            //Récupération des équipe formaté pour la liste de supression
            let listeEquipeDel = [];
            dataEquipes.forEach(equipe => {
                listeEquipeDel.push({value : equipe.ID_EQUIPE, label : equipe.LIBELLE_EQUIPE});
            });
            this.setState({listeEquipeDel});

            //Récupération des équipe formaté pour l'affichage Tree
            this.setState({
                listeEquipeTree: getTreeFromFlatData({ 
                    flatData: dataEquipes.map(equipe => ({ ...equipe, title: equipe.LIBELLE_EQUIPE, className : "icon" })), 
                    getKey: equipe => equipe.ID_EQUIPE, // resolve a node's key 
                    getParentKey: equipe => equipe.ID_PARENT, // resolve a node's parent's key 
                    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level) 
                  }),
            })
            this.setState({count : getVisibleNodeCount({treeData : this.state.listeEquipeTree})})            
            
            this.setState({loadingEquipe : false});
        }).catch(error => {
            console.log(error);
        });
    }

    //fonction permetant de formatter les données et de l'envoyer à l'api por modification
    enregistrer = (event) => {
        event.preventDefault();

        const getNodeKey = ({ treeIndex }) => treeIndex;

        const equipesFlat = getFlatDataFromTree({
            treeData: this.state.listeEquipeTree,
            getNodeKey, // This ensures your "id" properties are exported in the path
            ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
          }).map(({ node, path }) => ({
            ID_EQUIPE: node.ID_EQUIPE,
            LIBELLE_EQUIPE: node.LIBELLE_EQUIPE,
      
            // The last entry in the path is this node's key
            // The second to last entry (accessed here) is the parent node's key
            ID_PARENT: path.length > 1 ? path[path.length - 2] : null,
            
          }));

        let equipes=[];
        equipesFlat.forEach(equipe => {
            let node = {};
            if(equipe.ID_PARENT===null){
                node = {...equipe}
            }else{
                node = {...equipe, ID_PARENT : equipesFlat[equipe.ID_PARENT].ID_EQUIPE}
            }
            equipes.push(node);
        });
        
        
        const body = {
            idSociete : this.props.params.id,
            equipes : equipes
        };

        const Options = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        fetch('/api/equipes/modify', Options)
        .then(res => {
            if(res.status === 500){
                alert("Erreur durant la modification, veuillez réessayer ...");
            }else{
                alert("Modifications enregistrée !");
                this.props.navigate("/societes");
            }  
        }).catch(error => {
            console.log(error);
        });
    }
        



    

    //Fonction réalisant le chargement des informations de la page
    componentDidMount(){
        this.fetchSociete();
        this.fetchEquipes();
    }

    //Réalisé le rendu visuel
    render(){
        if(this.state.loadingSociete || this.state.loadingEquipe){
            return (
                <h1> </h1>
            )
        }else{
            return (
                <>
                    <div className="flex flex-col items-center justify-center h-full pt-32 pb-10">
                        <h1 className="text-orange-incubate text-3xl">Gérer les équipes de la société {this.state.libelleSociete}</h1>
                        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={() => {this.setState({showModalAdd:true})}}>Ajouter une équipe</button>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={() => {this.setState({showModalDel:true})}} >Supprimer une équipe</button>
                        </div>
                    </div>
                    <div className="flex flex-row text-orange-incubate">
                        <div className="w-1/3"></div>
                        {this.state.equipes.length>0 ? 
                        
                        <div style={{height: this.state.count*70}} className="w-1/3 h-full">
                            <SortableTree
                                treeData={this.state.listeEquipeTree}
                                onChange={listeEquipeTree => {
                                    this.setState({ listeEquipeTree : listeEquipeTree, modification : true, count : getVisibleNodeCount({treeData : listeEquipeTree}) });
                                }}
                                generateNodeProps={({ node }) => {
                                    return {
                                    className:  `${node.className}`,
                                    };
                                }}
                            />
                        </div> : <div className="w-1/3 h-full text-black flex flex-row items-center justify-center">Cette société ne possède aucune équipe</div>
                    }
                        
                        <div className="w-1/3"></div>
                    </div>
                    
                    
                    <div className="flex flex-col items-center justify-center h-full">
                        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={this.enregistrer}>Enregistrer</button>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={() => {this.state.modification ? this.setState({showModalAnnuler:true}) : this.props.navigate("/societes")}} >Annuler</button>
                        </div>
                    </div>
                        {this.state.showModalAdd  ? (
                            <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Création d'une équipe</h3>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <div className="md:mb-4">
                                            <label htmlFor='nom' className="block font-semibold">Nom de l'équipe</label>
                                            <input type='text' name='nom' placeholder="Nom de l'équipe" value={this.state.nom} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                                            {this.state.nomError ? <div className="text-red-600">{this.state.nomError}</div> : null }
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => {this.setState({showModalAdd:false}); this.setState({nom:''})}}>Annuler</button>
                                    <button
                                        className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={this.ajouter} >Ajouter</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {this.state.showModalDel  ? (
                            <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">Suppression d'une équipe</h3>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                    <label htmlFor='idEquipeDel' className="block font-semibold">Équipe à supprimer</label>
                                        {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (<blockquote>"{this.state.ariaFocusMessage}"</blockquote>)}
                                        <Select
                                            aria-labelledby="EquipeDel-label"
                                            inputId="Del-input"
                                            name="idEquipeDel"
                                            onMenuOpen={this.onMenuOpen}
                                            onMenuClose={this.onMenuClose}
                                            options={this.state.listeEquipeDel}
                                            onChange={this.handleChangeDel}
                                            defaultValue={this.state.listeEquipeDel[this.state.idEquipeDel]}
                                        />  
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalDel:false, idEquipeDel:null})}>Annuler</button>
                                    <button
                                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalValidDel:true})} >Supprimer</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {this.state.showModalValidDel  ? (
                            <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">ATTENTION</h3>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Vous êtes sur le point de supprimer une équipe !
                                        <br/>Si cette dernière possède des équipes, elles seront également supprimée.
                                        <br/>
                                        <br />Etes vous sûr de vouloir continuer ?
                                    </p>
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalValidDel:false, showModalDel:true})}>Annuler</button>
                                    <button
                                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={this.supprimer} >Supprimer</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {/*Modal annuler*/}

                        {this.state.showModalAnnuler ? (
                            <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">ATTENTION</h3>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Vos modifications ne seront pas sauvegardées !
                                        <br />Etes vous sûr de vouloir continuer ?
                                    </p>
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalAnnuler:false})}>Non</button>
                                    <button type='button' className="bg-orange-incubate text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={()=>{this.props.navigate("/societes")}}>Oui</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                </>
            );
        }
    }
}

//Export
export default withRouter(CreationEquipes);